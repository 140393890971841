/*------------------------------------------------------------------------------

  メガメニュー

------------------------------------------------------------------------------*/
$(window).on('load resize', function () {
  let x = 768,
    w = $(window).width();

  if (w >= x) {
    var navHeightArray = [];
    var megamenu = $('.header_nav_menu_list');
    var menuBg = $('.header_nav_bg');
    var menuBgDefHeight = menuBg.height();
    var menuTrigger = $('.header_nav_menu_item').not('.search');
    var menuNotTrigger = $('.header_nav_menu_item.search');
    var content = $('.header_nav_menu_under');
    var backBg = $('.dropdown-bg');

    navHeightSet();
    initialize();
    menuTriggerSet();

    function initialize() {
      menuBg.height(0);
      $('.header_nav_menu_under').removeAttr('style');
      menuTrigger.attr('area-expanded', 'false');
    }

    function background() {
      if ($('.dropdown-bg').hasClass('active')) {
        if ($('.search_area').css('display') == 'block') {
          backBg.addClass('active');
        } else {
          backBg.removeClass('active');
        }
      } else {
        backBg.removeClass('active');
      }
    }

    function navHeightSet() {
      menuTrigger.each(function (index) {
        navHeightArray[index] = $(this).find(content).height();
      });
    }

    function menuTriggerSet() {
      menuTrigger.hover(function () {
        var height = calcHeight($(this).index());
        bgHeightSet(height);
        $(this).attr('area-expanded', 'true');
        $(this).find(content).attr('area-transition', 'start');
        backBg.addClass('active');
      });
    }

    megamenu.mouseleave(function () {
      menuTrigger.attr('area-expanded', 'false');
      bgHeightSet(0);
      background();
    });

    menuTrigger.mouseleave(
      function () {
        $(this).attr('area-expanded', 'false');
        $(this).find(content).attr('area-transition', 'finish');
      }
    );

    menuNotTrigger.mouseenter(function () {
      menuTrigger.attr('area-expanded', 'false');
      bgHeightSet(0);
      if ($('.header_nav_menu_item.search').hasClass('active')) {
        backBg.addClass('active');
      } else {
        backBg.removeClass('active');
      }
    })

    function calcHeight(index) {
      var menuHeight = menuBgDefHeight + navHeightArray[index];
      return menuHeight;
    }

    function bgHeightSet(height) {
      menuBg.height(height);
    }
  } else {
    $('.header_nav_menu_ttl').removeClass('active');
    $('.header_nav_menu_under').removeAttr('style');
    $('.header_nav_menu_item:not(.search)').off('mouseenter mouseleave');
  }

});

/*------------------------------------------------------------------------------

  headerメニューにcurrentをつける

------------------------------------------------------------------------------*/
$(function () {
  var path = $(location).attr('pathname');
  var dir = path.split('/').slice(1)[0];

  $('.header_nav_menu_item > a').each(function () {
    var url = $(this).attr('href');
    url = url.replace(/\//g, '');

    if (url === dir) {
      $(this).parent().addClass('is-current');
    }
  });
});

/*------------------------------------------------------------------------------

  トップへ戻る

------------------------------------------------------------------------------*/
$(function () {
  $('.pagetop a').on('click', function () {
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;

    $("html, body").animate({
      scrollTop: position
    }, 500, "swing");

    return false;
  });
});

/*------------------------------------------------------------------------------

  ツールチップ

------------------------------------------------------------------------------*/
$(function () {
  $('.trashcan_btn').not('.is-disabled').hover(function () {
    let txt = $(this).find('.js-tooltip').attr('data-tooltip');

    $(this).append('<p class="tooltip">' + txt + '</p>');
    $(this).find('.tooltip').fadeIn('fast');
  }, function () {
    $(this).find('.tooltip').fadeOut('fast', function () {
      $(this).remove();
    });
  });

  $('.edit_btn').not('.is-disabled').hover(function () {
    let txt = $(this).find('.js-tooltip').attr('data-tooltip');

    $(this).append('<p class="tooltip">' + txt + '</p>');
    $(this).find('.tooltip').fadeIn('fast');
  }, function () {
    $(this).find('.tooltip').fadeOut('fast', function () {
      $(this).remove();
    });
  });
});

/*------------------------------------------------------------------------------

  ヘッダーメニュー

------------------------------------------------------------------------------*/
$(function () {
  $('.header_nav_menu_item:not(.other) .header_nav_menu_ttl').on('click', function () {
    if ($(window).width() < 768) {
      if ($(this).parent().hasClass('active')) {
        $(this).parent().removeClass('active');
        $(this).siblings('.header_nav_menu_under').stop().slideUp();
      } else {
        $(this).parent().addClass('active');
        $(this).siblings('.header_nav_menu_under').stop().slideDown();
      }

      return false;
    }
  })
});

/*------------------------------------------------------------------------------

  フッターメニュー

------------------------------------------------------------------------------*/
$(function () {
  $('.footer_upper_nav_clm:not(.other) .footer_upper_nav_ttl a').on('click', function () {
    if ($(window).width() < 768) {
      if ($(this).parent().hasClass('active')) {
        $(this).parent().removeClass('active');
        $(this).parent().siblings('.footer_upper_nav_list').stop().slideUp();
      } else {
        $(this).parent().addClass('active');
        $(this).parent().siblings('.footer_upper_nav_list').stop().slideDown();
      }

      return false;
    }
  })
});

/*------------------------------------------------------------------------------

  ヘッダー 検索

------------------------------------------------------------------------------*/
$(function () {
  var backBg = $('.dropdown-bg');

  $('.header_nav_menu_item.search span').on('click', function () {
    if ($(this).parent().hasClass('active')) {
      $(this).parent().removeClass('active');
      $('.search_area').stop().slideUp();
      backBg.removeClass('active');
    } else {
      $(this).parent().addClass('active');
      $('.search_area').stop().slideDown();
      backBg.addClass('active');
    }
  });

  $('.sp_search_btn').on('click', function () {
    if ($('.search_area').css('display') == 'block') {
      $(this).removeClass('active');
      $('.search_area').slideUp();
      $('.dropdown-bg').removeClass('active');
    } else {
      $(this).addClass('active');
      $('.search_area').slideDown();
      $('.dropdown-bg').addClass('active');
    }

    if ($('.header_nav').css('display') == 'block') {
      $('.header_nav').stop().slideUp();
    }

    if ($('.sp_menu_btn').hasClass('active')) {
      $('.sp_menu_btn').removeClass('active');
    }

  });
});

/*------------------------------------------------------------------------------

  SPメニュー

------------------------------------------------------------------------------*/
$(function () {
  $('.header').find('.sp_menu_btn').on('click', function () {
    if ($('.header_nav').css('display') == 'block') {
      $(this).removeClass('active');
      $('.header_nav').slideUp();
    } else {
      $(this).addClass('active');
      $('.header_nav').slideDown();
      $('.dropdown-bg').removeClass('active');
    }

    if ($('.sp_search_btn').hasClass('active')) {
      $('.sp_search_btn').removeClass('active');
      $('.search_area').fadeOut();
    }

  });
});

/*------------------------------------------------------------------------------

  Form チェックボックス

------------------------------------------------------------------------------*/
$(function () {
  var submitBtn = $('.form_submit_btn #submit_btn');

  submitBtn.prop('disabled', true);

  if ($('.g-recaptcha').length) {
    $('.check_box_inner').on('click', function () {
      if ($('#submit_btn').hasClass('recaptcha')) {
        if ($('input[name="checkbox"]').is(':checked')) {
          $('.form_submit_btn').removeClass('is-disable');
          submitBtn.prop('disabled', false);
        } else {
          $('.form_submit_btn').addClass('is-disable');
          submitBtn.prop('disabled', true);
        }
      }
    });
  } else {
    $('.check_box_inner').on('click', function () {
      if ($('.form_checkbox').is(':checked')) {
        $('.form_submit_btn').removeClass('is-disable');
        submitBtn.prop('disabled', false);
      } else {
        $('.form_submit_btn').addClass('is-disable');
        submitBtn.prop('disabled', true);
      }
    });
  }
});

/*------------------------------------------------------------------------------

  Form カテゴリー検索

------------------------------------------------------------------------------*/
/*
  Tab
------------------------------------------------------------------------------*/
$(function () {
  var index;

  $('.form_search_tab_item, [data-trigger="js-tab_menu"]').not('.disabled').on('click', function () {
    index = $(this).index();
    var text = $('.form_select').siblings('.select-options').find('li').eq(index).text();
    var selectItem = $('.form_select').siblings('.select-options').find('li').eq(index);

    $('.form_search_area.category').find('.btn_wrapper button').prop('disabled', true);
    $('.form_search_checkbox').prop('checked', false);

    if ($(window).width() >= 768) {
      if ($(this).parent().hasClass('current')) {
        $(this).removeClass('current');
      } else {
        $('.form_search_tab_item').removeClass('current');
        $(this).addClass('current');
      }
    }

    $('.form_search_tabContent_block').hide();
    $('.form_search_tabContent_block').eq(index).show();

    $('.form_select').siblings('.select-styled').text(text);
    selectItem.val(selectItem.attr('rel'));

    $('.form_select').val(selectItem.attr('rel'));
  });
});

$(function () {
  var num;

  $('.form_search_tab_item').each(function () {
    if ($(this).hasClass('current')) {
      num = $(this).index();
    }
  });

  $('.form_select').siblings('.select-options').find('li').on('click', function () {
    var index = $(this).index();

    $('.form_search_tab_item').each(function () {
      if ($(this).hasClass('current')) {
        num = $(this).index();
      }
    });

    if (num !== index) {
      $('.form_search_area.category').find('.btn_wrapper button').prop('disabled', true);
      $('.form_search_checkbox').prop('checked', false);
    }

    $('.form_search_tab_item').removeClass('current');
    $('.form_search_tab_item').eq(index).addClass('current');

    $('.form_search_tabContent_block').hide();
    $('.form_search_tabContent_block').eq(index).show();
  });
});

/*
  Tab Content
------------------------------------------------------------------------------*/
$(function () {
  var allBtn = $('.form_search_tabContent_item.all').find('input[type       = "checkbox"]');
  var otherBtn = $('.form_search_tabContent_item:not(.all)').find('input[type = "checkbox"]');
  var btn = $('.form_search_tabContent_item').find('input[type           = "checkbox"]');
  var category_btn = $('.form_search_area.category');
  var index;

  category_btn.find('.btn_wrapper button').prop('disabled', true);

  $('.form_search_tab_item').each(function () {
    if ($(this).hasClass('current')) {
      index = $(this).index();
      var text = $('.form_select').siblings('.select-options').find('li').eq(index).text();
      var selectItem = $('.form_select').siblings('.select-options').find('li').eq(index);
      var checkedTabContentItem = $('.form_search_tabContent_block').eq(index).find('input[type="checkbox"]:checked');

      $('.form_select').siblings('.select-styled').text(text);
      selectItem.val(selectItem.attr('rel'));

      $('.form_search_tabContent_block').hide();
      $('.form_search_tabContent_block').eq(index).show();

      category_btn.find('.btn_wrapper button').prop('disabled', checkedTabContentItem.length == 0);
    }
  });

  allBtn.on('click', function () {
    $(this).parents('.form_search_tabContent_list').find('input[type="checkbox"]').prop('checked', this.checked);
  });

  otherBtn.on('click', function () {
    if ($(this).parents('.form_search_tabContent_list').find('.form_search_tabContent_item:not(.all) :checked').length == $(this).parents('.form_search_tabContent_list').find('.form_search_tabContent_item:not(.all) :input').length) {
      allBtn.prop('checked', true);
    } else {
      allBtn.prop('checked', false);
    }
  });

  btn.on('click', function () {
    if ($('.form_search_tabContent_item').find('input[type="checkbox"]:checked').length) {
      category_btn.find('.btn_wrapper button').prop('disabled', false);
    } else {
      category_btn.find('.btn_wrapper button').prop('disabled', true);
    }
  });
});

/*------------------------------------------------------------------------------

  モジュールニュース

------------------------------------------------------------------------------*/
$(function () {
  let index;

  $('[data-trigger="news"] .news_nav_item p').on('click', function () {
    index = $(this).parent().index();

    // ニュースタブ
    $('[data-trigger="news"] .news_nav_item').removeClass('current');
    $(this).parent().addClass('current');

    // ニュースコンテンツ
    $('.news_headline').hide();
    $('.news_headline').eq(index).show();
  });
});

/*------------------------------------------------------------------------------

  モジュールホバー

------------------------------------------------------------------------------*/
$(function () {
  $(document).on("mouseenter", "[data-trigger='hover-m']", function () {
    $(this).parents(".point-h").find('[data-trigger="hover-s"]').addClass("opt-img-scale-h");
  });

  $(document).on("mouseleave", "[data-trigger='hover-m']", function () {
    $(this).parents(".point-h").find('[data-trigger="hover-s"]').removeClass("opt-img-scale-h");
  });
});

/*------------------------------------------------------------------------------

  モジュールアンカー

------------------------------------------------------------------------------*/
$(function () {
  $('[data-trigger="js-anchor"] a[href^="#"], [data-trigger="js-anchor"] area[href^="#"], a[href^="#"].mod-link_inline').on('click', function () {
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var height = 40;
    var header = $('.header_menu').height();
    var position = target.offset().top - header - height;

    $("html, body").animate({
      scrollTop: position
    }, 500, "swing");

    return false;
  });
});

$(function () {
  $('.js-toTop a[href^="#"]').on('click', function () {
    $('body, html').animate({ scrollTop: 0 }, 500);
    return false;
  });
});

$(function () {
  var index;
  var strings;

  $('[data-trigger="js-hash"]').on('click', function () {
    var obj = $(this).attr('href');

    if (obj !== '') {
      hashLink(obj, index, strings);
    }

    return false;
  });
});

function hashLink(obj, index, strings) {
  $('.mod-tab_content_block').each(function () {
    if ($(obj, this)[0]) {
      index = $(this).closest('.mod-tab_content_block').index();
      strings = $(this).closest('.mod-tab_content').siblings('.mod-tab_menu').find('.mod-tab_menu_item').eq(index).find('p').text();

      $(this).parents('.mod-tab_content').find('.mod-tab_content_block').hide();
      $(this).closest('.mod-tab_content_block').show();
      $(this).closest('.mod-tab_content').siblings('.mod-tab_menu').find('.mod-tab_menu_item').removeClass('current');
      $(this).closest('.mod-tab_content').siblings('.mod-tab_menu').find('.mod-tab_menu_item').eq(index).addClass('current');

      if ($('.mod-search_select').length) {
        $('.select-styled').text(strings);
      }

      return false;
    }
  });
}
$(window).on('load', function () {
  var hash = $(location).attr('hash');
  var href = $(this).attr("href");
  var target = $(href == "#" || href == "" ? 'html' : href);
  var height = 40;
  var header = $('.header_menu').height();
  if (hash) {
    var position = $(hash).offset().top - header - height;
    $('html,body').animate({ scrollTop: position }, 'swing');
  }
});


/*------------------------------------------------------------------------------

  モジュールタブ

------------------------------------------------------------------------------*/
$(function () {
  $('[data-trigger="js-tab_menu"] [class*="mod-tab"][class*="_item"] > *').on('click', function () {
    var index = $(this).parent().index();
    var number = $(this).parent().attr('data-tab-number');
    var strings = $(this).text();

    tabAddClass(index);

    if (number !== undefined) {
      $('[data-trigger="js-tab_menu"] [class*="mod-tab"][class*="_item"]').each(function () {
        var value = $(this).attr('data-tab-number');

        if (value == number) {
          $(this).addClass('current');

          tabAddClass(number);
        }
      });
    }

    if ($('.mod-search_select').length) {
      $('.select-styled').text(strings);
    }

    return false;
  });
});

function tabAddClass(num) {
  $('[data-trigger="js-tab_menu"] [class*="mod-tab"][class*="_item"]').removeClass('current');
  $('[data-trigger="js-tab_menu"] [class*="mod-tab"][class*="_item"]').eq(num).addClass('current');

  $('[data-trigger="js-tab_content"] > div').hide();
  $('[data-trigger="js-tab_content"] > div').eq(num).show();
}

$(function () {
  $('.form_select').siblings('.select-options').find('li').on('click', function () {
    var index = $(this).index();

    $('.mod-tab_menu_item').removeClass('current');
    $('.mod-tab_menu_item').eq(index).addClass('current');

    $('.mod-tab_content_block').hide();
    $('.mod-tab_content_block').eq(index).show();
  });
});

$(function () {
  var currentActiveTabIndex = $('[data-trigger="js-search_tab"] .mod-search_tab_clm.current').index();
  openActiveTabContent(currentActiveTabIndex);

  $('[data-trigger="js-search_tab"] [class*="_tab_clm"] > *').not('.opt-tab_link').on('click', function () {
    var index = $(this).parent().index();

    $('[data-trigger="js-search_tab"] [class*="_tab_clm"]').removeClass('current');
    $(this).parent().addClass('current');

    openActiveTabContent(index);
  });

  /**
   * @param {number} index - アクティブにするタブのインデックス番号
   */
  function openActiveTabContent(index) {
    $('[data-trigger="js-search_content"] > div').hide();
    $('[data-trigger="js-search_content"] > div').eq(index).show();
  }
});

/*------------------------------------------------------------------------------

  モジュールアコーディオン

------------------------------------------------------------------------------*/
$(document).on('ready',function(){
  $.fn.matchHeight._update();
})
$(window).on('load', function () {
  let $accordion = $('[data-trigger = "js-accordion"]');
  let speed = 300;
  $accordion.find('[class*="-accordion_ttl"]').on('click', function () {
    var id=$(this).parent().parent().attr('id');
    if ($(this).parent().hasClass('open')) {
      $(this).parent().removeClass('open');
      $(this).siblings('[class*="-accordion_content"]').stop().slideUp(speed);
    } else {
      if(id){
        location.hash='#'+id;
      }
      $(this).parent().addClass('open');
      $(this).siblings('[class*="-accordion_content"]').stop().slideDown(speed);
    }
  });
  var hash=location.hash;
  if(hash){
    console.log($(hash));
    $(hash).find('[class*="-accordion_content"]').parent().addClass('open');
    $(hash).find('[class*="-accordion_content"]').stop().slideDown(speed);
  }
})


/*------------------------------------------------------------------------------

  モジュールカルーセル

------------------------------------------------------------------------------*/
var mod_swiper;
var elmSwiper = document.querySelectorAll('.mod-swiper-slide');
var elmPrev = document.querySelectorAll('.js-swiper-prev');
var elmNext = document.querySelectorAll('.js-swiper-next');

function mod_slideFunc() {
  var w = $(window).width();

  if (w <= 768) {
    if (mod_swiper) {
      return;
    } else {
      if (elmSwiper.length > 0) {
        for (let i = 0; i < elmSwiper.length; i++) {
          elmSwiper[i].className += i;
          elmPrev[i].className += i;
          elmNext[i].className += i;
          mod_swiper = new Swiper('.mod-swiper-slide' + i, {
            slidesPerView: 1,
            allowTouchMove: true,
            resistanceRatio: 0,
            navigation: {
              nextEl: '.js-swiper-next' + i,
              prevEl: '.js-swiper-prev' + i,
              hideOnClick: true,
            }
          });
        }
      }
    }
  } else {
    if (mod_swiper) {
      mod_swiper.destroy(false, true);
      mod_swiper = undefined;
    }
  }
}

$(window).on('load resize', function () {
  mod_slideFunc();
});

/*------------------------------------------------------------------------------

  リンクにcurrentをつける

------------------------------------------------------------------------------*/
$(function () {
  var path = $(location).attr('pathname');
  var index = path.split('/').slice(-1)[0];

  if (index === 'index.html') path = path.replace('index.html', '');

  $('[data-trigger="js-link"]').find('li').each(function () {
    var url = $(this).children('a').attr('href');

    if (url === path) {
      $(this).addClass('current');
    }
  });
});

/*------------------------------------------------------------------------------

  モーダル

------------------------------------------------------------------------------*/
$(function () {
  if ($('[data-trigger="js-modal"] .iziModal').length) {
    $('[data-trigger="js-modal"] .iziModal').iziModal({
      overlayColor: 'rgba(0, 0, 0, .5)',
      loop: true,
    });
  }
});

$(function () {
  $('[data-trigger="js-modal"] .opt-modal').modaal({
    type: 'inline',
  });

  $('[data-trigger="js-modal"] .opt-modal-movie').modaal({
    type: 'video'
  });
});

/*------------------------------------------------------------------------------

  画像サイズ

------------------------------------------------------------------------------*/
$(function () {
  $('.opt-img-width200').each(function () {
    var image = $(this).width();
    var image_size = Math.floor(image / 2);

    $(this).css({
      width: image_size,
      visibility: 'visible'
    });
  });

  $('.opt-img-width150').each(function () {
    var image = $(this).width();
    var image_size = Math.floor(image / 1.5);

    $(this).css({
      width: image_size,
      visibility: 'visible'
    });
  });
});

/*------------------------------------------------------------------------------

  もっと表示する

------------------------------------------------------------------------------*/
$(function () {
  var itemNum = parseInt($('[data-trigger="js-moreBtn_content"]').attr('data-view'));
  var btn = $('[data-trigger="js-moreBtn"]');
  var moreNum = 9;
  var moreBtnContentPerSectionGroup = $('[data-trigger="js-moreBtn_content"]');

  moreBtnContentPerSectionGroup.each(function (index, element) {
    var contentItems = $(element).find('.js-moreBtn_item');
    var totalItem = contentItems.length;
    var btnPerSectionGroup = $(element).siblings('[data-trigger = "js-moreBtn"]');

    if (totalItem <= itemNum) {
      btnPerSectionGroup.hide();
    } else {
      contentItems.each(function (itemIndex, item) {
        if (itemIndex >= itemNum) {
          $(item).hide().addClass('opt-is-hidden');
        }
      });
    }
  });

  btn.find('button').on('click', function () {
    var moreBtn = $(this).parents('[data-trigger="js-moreBtn"]');
    var moreBtnContent = moreBtn.siblings('[data-trigger="js-moreBtn_content"]');
    moreBtnContent.find('.js-moreBtn_item.opt-is-hidden').slice(0, moreNum).fadeIn().removeClass('opt-is-hidden');

    if (moreBtnContent.find('.js-moreBtn_item.opt-is-hidden').length == 0) {
      moreBtn.hide();
    }
  });
});

/*------------------------------------------------------------------------------

  セレクトボックス

------------------------------------------------------------------------------*/
$('.js-selectBox').each(function () {
  var $this = $(this),
    numberOfOptions = $(this).children('option').not('.first, .is-hidden').length;

  $this.addClass('select-hidden');
  $this.wrap('<div class="select"></div>');
  $this.after('<div class="select-styled"></div>');

  var $styledSelect = $this.next('div.select-styled');
  if ($this.children('option:selected').hasClass('is-hidden')) {
    $styledSelect.text($this.children('option:selected').next().text());
  } else {
    $styledSelect.text($this.children('option:selected').text());
  }

  var $list = $('<ul />', {
    'class': 'select-options'
  }).insertAfter($styledSelect);

  for (var i = 0; i < numberOfOptions; i++) {
    $('<li />', {
      text: $this.children('option').not('.first, .is-hidden').eq(i).text(),
      rel: $this.children('option').not('.first, .is-hidden').eq(i).val()
    }).appendTo($list);
  }

  var $listItems = $list.children('li');

  $styledSelect.click(function (e) {
    e.stopPropagation();
    $('div.select-styled.active').not(this).each(function () {
      $(this).removeClass('active').next('ul.select-options').hide();
    });
    $(this).toggleClass('active').next('ul.select-options').toggle();
  });

  $listItems.click(function (e) {
    e.stopPropagation();
    $styledSelect.text($(this).text()).removeClass('active');
    $this.val($(this).attr('rel'));
    $list.hide();
  });

  $(document).click(function () {
    $styledSelect.removeClass('active');
    $list.hide();
  });
});

$(function () {
  var year;
  var firstYear;
  var list = $('.js-calendar .select-options li')
  var styled = $('.js-calendar .select-styled');

  firstYear = list.first().attr('rel');

  styled.text(firstYear + '年度');

  styled.on('click', function () {
    $(this).siblings('.select-options').toggle();
  });

  list.on('click', function () {
    year = $(this).attr('rel');

    $(this).parent().siblings('.select-styled').text(year + '年度');
    $(this).parent().hide();
  });
});

/*------------------------------------------------------------------------------

  絞り込み

------------------------------------------------------------------------------*/
$(function () {
  var d = new Date();
  var year = d.getFullYear();
  var yearSelect;
  var yearBlock;

  $('[data-trigger="mod-year_select"]').find('.select-options li').on('click', function () {
    yearSelect = $(this).attr('rel');

    $('[data-trigger="mod-year_block"]').find('.mod-selectBox_block').each(function () {
      yearBlock = $(this).attr('data-year');

      if (yearSelect == yearBlock) {
        $('.mod-selectBox_block').hide();
        $(this).show();
      }
    });
  });
});

$(function () {
  var d = new Date();
  var year = d.getFullYear();
  var month = d.getMonth() + 1;
  var day = d.getDate();
  var today;
  var dateList;
  var dateBlock;
  var optDate;
  var firstYear;

  if (month < 10) {
    month = '0' + month;
  }

  if (day < 10) {
    day = '0' + day;
  }

  today = year + '' + month + '' + day;

  $('.select-options').find('li').each(function () {
    optDate = $(this).attr('data-time');

    if (optDate >= today) {
      $(this).addClass("is-hidden");
    }

    if ($(this).hasClass('is-hidden')) {
      firstYear = $('.select-options li').not('.is-hidden').first().attr('rel');

      $('.select-styled').text(firstYear + '年度');
    }
  });

  $('.calendar_content').find('.calendar_content_item').each(function () {
    dateList = $(this).attr('data-time');

    if (dateList >= today) {
      $(this).addClass('on');
    } else {
      $(this).addClass('off');
    }
  });

  $('.calendar_content').find('.mod-selectBox_block').each(function () {
    dateBlock = $(this).attr('data-time');

    if ($(this).attr('data-time') !== 'undefined') {
      if (dateBlock >= today) {
        $(this).hide();
        $(this).next().show();
      }
    }
  });

  if (!($('.calendar_content.after .calendar_content_item.on').length)) {
    $('.calendar_after_txt').addClass('is-show');
  }
});

/*------------------------------------------------------------------------------

  イメージマップ レスポンシブ化

------------------------------------------------------------------------------*/
$(function () {
  $('map').imageMapResize();

  $('[data-trigger="js-tab_menu"] [class*="mod-tab"][class*="_item"] > *').on('click', function () {
    $('map').imageMapResize();
  });
});

/*------------------------------------------------------------------------------

  文字数制限

------------------------------------------------------------------------------*/
$(function () {
  $('.opt-txt-limit').each(function () {
    var thisText = $(this).text();
    var textLength = thisText.length;
    var count = $(this).attr('data-count');

    if (textLength > count) {
      var showText = thisText.substring(0, count);
      var insertText = showText += '…';
      $(this).html(insertText);
    }
  });
});

/*------------------------------------------------------------------------------

  クッキーポリシー

------------------------------------------------------------------------------*/
$(function () {
  if (Cookies.get('cookie') !== undefined) {
    $('.cookie').hide();
  } else {
    $('.cookie').fadeIn();
  }

  $('.cookie_btn').on('click', function () {
    Cookies.set('cookie', 'visited', { expires: 30 });
    $('.cookie').hide();

    return false;
  });

  $('.cookie_close').on('click', function () {
    $('.cookie').hide();

    return false;
  });
});

/*------------------------------------------------------------------------------

  iframe

------------------------------------------------------------------------------*/
$(window).on('load resize', function () {
  const frame = $('#euroland_frame_id');

  function height() {
    frame.style.width = frame.contentWindow.document.body.scrollWidth + "px";
    frame.style.height = frame.contentWindow.document.body.scrollHeight + "px";
  }
});

/*------------------------------------------------------------------------------

  Height

------------------------------------------------------------------------------*/
$(window).on('load resize', function () {
  var height;
  $('[data-trigger="parentHeight"]').each(function () {
    height = $(this).parent().height();

    $(this).height(height);
  });
});

/*------------------------------------------------------------------------------

  Sort

------------------------------------------------------------------------------*/
$(function () {
  var filterMenu;
  var filterTarget;
  var selectText;
  var filterFirst;

  $('[data-trigger="js-filter"]').each(function () {
    filterFirst = $(this).find('.mod-filter_item').first().data('filter');

    $(this).find('.mod-filter_item').first().addClass('is-current');

    $(this).closest('.mod-sort').find('.mod-filter_content').each(function () {
      filterTarget = $(this).data('filter');

      if (filterFirst == filterTarget["syllabary"]) {
        $(this).show();
      } else {
        $(this).hide();
      }
    });
  });

  $('[data-trigger="js-filter"] .mod-filter_item').on('click', function () {
    filterMenu = $(this).attr('data-filter');
    selectText = $(this).closest('.mod-sort').find('[data-trigger="js-sort_select"] .first').text();

    $(this).closest('.mod-sort').find('[data-trigger="js-sort_select"] .select-styled').text(selectText);
    $(this).closest('.mod-sort').find('[data-trigger="js-sort_select"] select').val(0);
    $(this).parent().find('.mod-filter_item').removeClass('is-current');
    $(this).addClass('is-current');

    $(this).closest('.mod-sort').find('.mod-filter_content').each(function () {
      filterTarget = $(this).data('filter');

      if (filterMenu == filterTarget["syllabary"]) {
        $(this).show();
      } else {
        $(this).hide();
      }
    });
  });
});


$(function () {
  var group;
  var filterTarget;

  $('[data-trigger="js-sort_select"] .select-options li').on('click', function () {
    group = $(this).attr('rel').replace('group', '');

    $(this).closest('.mod-sort').find('[data-trigger="js-filter"] .mod-filter_item').removeClass('is-current');

    $(this).closest('.mod-sort').find('.mod-filter_content').each(function () {
      filterTarget = $(this).data('filter');

      if (group == filterTarget["group"]) {
        $(this).show();
      } else {
        $(this).hide();
      }
    });
  });
});

/*------------------------------------------------------------------------------

  aタグの処理

------------------------------------------------------------------------------*/
$(function () {
  var height = 40;
  var header = $('.header_menu').height();
  var parameter = $(location).search;
  var path = $(location).attr('pathname');
  var index = path.split('/').slice(-1)[0];

  if (index === 'index.html') path = path.replace('index.html', '');

  if (parameter !== '') path = path.replace(parameter, '');

  if (path == '/product/' || path == '/inquiry/sds/search') {
    if (parameter !== '') {
      var p = $('#result').offset().top - header - height;

      $('html,body').animate({ scrollTop: p }, 500, "swing");
    }
  }
});


/*------------------------------------------------------------------------------

  ページ外から遷移した際のタブ挙動

------------------------------------------------------------------------------*/
$(function () {
  var margin = 40;
  var h = $('.header_menu').height();
  var urlHash = location.hash;
  var index;
  var strings;

  if (urlHash) {
    setTimeout(function () {
      var target = $(urlHash);
      var p = target.offset().top - h + margin;

      $('html,body').stop().scrollTop(0).animate({ scrollTop: p }, 500, "swing");
    }, 100);

    hashLink(urlHash, index, strings);
  }
});

/*------------------------------------------------------------------------------

  Print

------------------------------------------------------------------------------*/
$(function () {
  $('.mod-print a').on('click', function () {
    window.print();

    return false;
  });
});
